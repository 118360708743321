var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"copy-stake-streamer-dialog",attrs:{"max-width":"700px","persistent":"","scrollable":"","no-click-animation":_vm.processing},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"loading":_vm.processing}},[_c('v-card-title',{staticClass:"text-h6 text-wrap"},[_vm._v(_vm._s(_vm.header)),_c('v-spacer'),_c('v-btn',{staticClass:"rounded-0",attrs:{"icon":""}},[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-close')},on:{"click":_vm.close}})],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"disabled":_vm.processing},on:{"submit":function($event){$event.preventDefault();return _vm.handlerOnSubmit.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('ImageUploader',{ref:"imageUploader",attrs:{"image-url":_vm.imageUrl,"hint":_vm.imageRequirements,"max-file-size":_vm.maxFileSize,"rules":_vm.rules.image,"loading":_vm.processing},on:{"change":_vm.handlerOnUploadImage}}),_c('v-text-field',{attrs:{"outlined":"","label":"Nickname","placeholder":"Nickname","error-messages":_vm.serverError.nickname,"rules":_vm.rules.nickname},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('div',{domProps:{"innerHTML":_vm._s(message)}})]}}]),model:{value:(_vm.formData.nickname),callback:function ($$v) {_vm.$set(_vm.formData, "nickname", $$v)},expression:"formData.nickname"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.streamerTypes,"label":"Streamer`s type","item-text":"label","menu-props":{ offsetY: true, contentClass: 'list-style' },"outlined":"","rules":_vm.rules.type},model:{value:(_vm.formData.type),callback:function ($$v) {_vm.$set(_vm.formData, "type", $$v)},expression:"formData.type"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.operatorLanguages,"label":"Streamer`s language","item-text":"label","menu-props":{ offsetY: true, contentClass: 'list-style' },"outlined":"","rules":_vm.rules.language},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex flex-nowrap align-center"},[_c('div',{staticClass:"flag sm",class:'flag-' + item.value}),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.label))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticStyle:{"height":"46px"}},'v-list-item',attrs,false),on),[_c('div',{staticClass:"d-flex flex-nowrap ml-2"},[_c('div',{staticClass:"flag",class:'flag-' + item.value}),_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(item.label))])])])]}}]),model:{value:(_vm.formData.language),callback:function ($$v) {_vm.$set(_vm.formData, "language", $$v)},expression:"formData.language"}})],1)],1),_c('v-text-field',{attrs:{"outlined":"","label":"Wallet Address","placeholder":"Wallet Address","rules":_vm.rules.userWalletAddress,"error-messages":_vm.serverError.userWalletAddress},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_c('div',{domProps:{"innerHTML":_vm._s(message)}})]}}]),model:{value:(_vm.formData.userWalletAddress),callback:function ($$v) {_vm.$set(_vm.formData, "userWalletAddress", $$v)},expression:"formData.userWalletAddress"}}),_c('v-select',{attrs:{"items":_vm.streamerChannelTypes,"label":"Streamer Channel Type","item-text":"label","menu-props":{ offsetY: true, contentClass: 'list-style' },"outlined":"","rules":_vm.rules.streamingType,"error-messages":_vm.serverError.streamingType},model:{value:(_vm.formData.streamingType),callback:function ($$v) {_vm.$set(_vm.formData, "streamingType", $$v)},expression:"formData.streamingType"}}),_c('v-text-field',{staticClass:"copy-stake-streamer-dialog__access-code-input",attrs:{"outlined":"","label":"Access code","placeholder":"Access code","rules":_vm.rules.accessCode,"counter":"6","maxlength":"6","error-messages":_vm.serverError.accessCode},model:{value:(_vm.formData.accessCode),callback:function ($$v) {_vm.$set(_vm.formData, "accessCode", $$v)},expression:"formData.accessCode"}})],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"secondary","disabled":_vm.processing},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","loading":_vm.processing},on:{"click":_vm.handlerOnSubmit}},[_vm._v(_vm._s(_vm.submitText))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }